<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#64d6e2" fullScreen=true
    type="ball-clip-rotate-multiple">
</ngx-spinner>


<div [className]="checkpopup ? 'modal fade in' : 'modal fade'" [style.display]="showModal ? 'block' : 'none'"
    style="opacity: 1;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="    margin-top: 80px;">
            <!-- <div class="modal-header"> -->
                <!-- <h5 class="modal-title" id="exampleModalLabel">Change Password</h5> -->
            <!-- </div> -->
            <div class="modal-body custom-modal-body-Aj">
                <div class="container-fluid">
                    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="form-horizonatal">
                        <h1 class="login_heading" style="font-size: 23px;
                        margin-bottom: 20px;">Change Password</h1>
                        <label id="mainError" style="display:none;">Entered old Password is Wrong,please provide correct
                            password.</label>
    
                        <div class="row" style="margin-bottom: 20px;">
                            <div class="col-sm-12 col-xs-12 col-centered" style=" margin-bottom: -20px !important;">
                                <input type="password" formControlName="oldPassword" class="form-control login_text_field"
                                    placeholder="OLD PASSWORD"><br>
                            </div>
                            <div class="col-sm-12 col-xs-12 col-centered"
                                *ngIf="submitted && f.oldPassword.errors">
                                <div style="color: red;" *ngIf="f.oldPassword.errors.required">Old Password is required
                                </div>
                            </div>
                        </div>
    
                        <div class="row" style="margin-bottom: 20px;">
                            <div class="col-sm-12 col-xs-12 col-centered" style=" margin-bottom: -20px !important;">
                                <input type="password" formControlName="newPassword" class="form-control login_text_field"
                                    placeholder="NEW PASSWORD"><br>
                            </div>
                            <div class="col-sm-12 col-xs-12 col-centered"
                                *ngIf="submitted && f.newPassword.errors">
                                <div style="color: red;" *ngIf="f.newPassword.errors.required">New Password is required
                                </div>
                            </div>
                        </div>
    
                        <div class="row" style="margin-bottom: 20px;">
                            <div class="col-sm-12 col-xs-12 col-centered" style=" margin-bottom: -20px !important;">
                                <input type="password" formControlName="rePassword" class="form-control login_text_field"
                                    placeholder="CONFIRM PASSWORD"><br>
                            </div>
                            <div class="col-sm-12 col-xs-12 col-centered"
                                *ngIf="submitted && f.rePassword.errors">
                                <div style="color: red;" *ngIf="f.rePassword.errors.required">Re Password is required</div>
                                <div style="color: red;" *ngIf="f.rePassword.errors.mustMatch">Passwords must match
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 20px;">
                            <div class="col-lg-12 col-md-12">
                                <button [disabled]="submitted && passwordForm.invalid" type="submit"
                                    class="btn btn-primary purp_btn change_password_btn">Change Password</button>
                                    <button type="submit"
                                class="btn btn-danger purp_btn change_password_btn" style="float: right;" (click)="logout()">Logout</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>