import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LevelGuard } from './core/guards/level.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./module/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard, LevelGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./module/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'inplay',
        loadChildren: () =>
          import('./module/inplay/inplay.module').then(m => m.InplayModule)
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./module/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'casino',
        loadChildren: () =>
          import('./module/casino/casino.module').then(m => m.CasinoModule)
      },
      {
        path: 'jantri',
        loadChildren: () =>
          import('./module/jantri/jantri.module').then(m => m.JantriModule)
      }
    ]
  },
  // Fallback when no prior routes is matched
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' },)],
  exports: [RouterModule],
  providers: [AuthGuard, LevelGuard]
})
export class AppRoutingModule { }
