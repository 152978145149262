import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        let isAuth = this.authService.getIsAuth();
        const token = localStorage.getItem("token");
        if (token == null || token == 'null') {
            isAuth = false;
        }

        if (!isAuth) {
            this.router.navigate(['/auth/login']);
        }
        return isAuth;
    }
}