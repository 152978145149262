import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

const BACKEND_URL = environment.apiUrl + '/user';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private isAuthenticated = false;
    private token: string;
    private tokenTimer: any;
    private userId: string;
    private timeStamp: string;
    private userName: string;
    private name: string;
    private level: string;
    private error = '';
    private authStatusListner = new Subject<boolean>();
    constructor(private http: HttpClient, private router: Router) { }

    getToken() {
        return this.token;
    }

    getIsAuth() {
        return this.isAuthenticated;
    }

    getUserId() {
        return this.userId;
    }

    getTimeStamp() {
        return this.timeStamp;
    }

    getUserName() {
        return this.userName;
    }

    getUserLevel() {
        return this.level;
    }

    getName() {
        return this.name;
    }

    login(userName: string, password: string) {
        const authData = { userName, password };
        this.http.post<{ message: string, token: string, expiresIn: number, id: string, userName: string, name: string, level: string, timeStamp: string, AXR: string }>(
            BACKEND_URL + "/userLogin", authData)
            .subscribe(response => {
                const token = response.token;
                const message = response.message;
                this.token = token;
                if (token) {
                    this.error = '';
                    const expiresInDuration = response.expiresIn;
                    this.setAuthTimer(expiresInDuration);
                    this.isAuthenticated = true;
                    this.userId = response.id;
                    this.timeStamp = response.timeStamp;
                    this.userName = response.userName;
                    this.name = response.name;
                    this.level = response.level;
                    this.authStatusListner.next(true);
                    const now = new Date();
                    const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
                    //console.log(expirationDate);
                    this.saveAuthData(token, expirationDate, this.userId, this.userName, this.name, this.level, this.timeStamp, response.AXR);
                    this.router.navigate(['/dashboard']);
                }
                else {
                    this.error = message;
                    this.authStatusListner.next(false);
                }
            }, error => {
                this.error = error.error.message;
                this.authStatusListner.next(false);
            });
    }

    getError() {
        return this.error;
    }

    getAuthStatusListner() {
        return this.authStatusListner.asObservable();
    }

    autoAuthUser() {
        const authInformation = this.getAuthData();
        if (!authInformation) {
            return;
        }
        const now = new Date();
        const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
        if (expiresIn > 0) {
            this.token = authInformation.token;
            this.isAuthenticated = true;
            this.userId = authInformation.userId;
            this.timeStamp = authInformation.timeStamp;
            this.userName = authInformation.userName;
            this.name = authInformation.name;
            this.level = authInformation.level;
            this.setAuthTimer(expiresIn / 1000);
            this.authStatusListner.next(true);
        }
    }

    logout() {
        this.http.post(BACKEND_URL + "/logout", {}).subscribe(data => {
            //console.log(data);
            this.dlogout();
        }, error => {
            this.dlogout();
            console.log(error);
        });
    }

    dlogout() {
        this.token = null;
        this.isAuthenticated = false;
        this.level = null;
        this.authStatusListner.next(false);
        clearInterval(this.tokenTimer);
        this.clearAuthData();
        this.userId = null;
        this.timeStamp = null;
        this.userName = null;
        this.name = null;
        this.router.navigate(['/auth/login']);
    }

    private setAuthTimer(duration: number) {
        //console.log("Setting Timer " + duration);
        this.tokenTimer = setTimeout(() => {
            this.logout();
        }, duration * 1000);
    }

    private saveAuthData(token: string, expirationDate: Date, userId: string, userName: string, name: string, level: string, timeStamp: string, AXR:any) {
        localStorage.setItem('token', token);
        localStorage.setItem('expiration', expirationDate.toISOString());
        localStorage.setItem('userId', userId);
        localStorage.setItem('timeStamp', timeStamp);
        localStorage.setItem('userName', userName);
        localStorage.setItem('name', name);
        localStorage.setItem('level', level);
        localStorage.setItem('exA', AXR);
    }

    private clearAuthData() {
        localStorage.removeItem('token');
        localStorage.removeItem('expiration');
        localStorage.removeItem('userId');
        localStorage.removeItem('timeStamp');
        localStorage.removeItem('userName');
        localStorage.removeItem('name');
        localStorage.removeItem('level');
        localStorage.removeItem('exA');
    }

    private getAuthData() {
        const token = localStorage.getItem("token");
        const expirationDate = localStorage.getItem("expiration");
        const userId = localStorage.getItem("userId");
        const timeStamp = localStorage.getItem("timeStamp");
        const userName = localStorage.getItem("userName");
        const name = localStorage.getItem("name");
        const level = localStorage.getItem("level");
        const exa = localStorage.getItem("exA");
        if (!token || !expirationDate) {
            return;
        }
        return {
            token,
            expirationDate: new Date(expirationDate),
            userId,
            timeStamp,
            userName,
            exa,
            name,
            level
        }
    }
}