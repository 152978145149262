import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { SocketService } from 'src/app/service/socket.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userName: any;
  name: any;
  userId: any;
  user: any;
  message: any;
  private userSubs: Subscription;
  private messageSubs: Subscription;
  constructor(private authService: AuthService, private userService: UserService,
    private socketService: SocketService) { }

  ngOnInit(): void {
    this.userName = this.authService.getUserName();
    this.name = this.authService.getName();
    this.userId = this.authService.getUserId();
    this.userService.getUserById(this.userId);
    this.socketService.setMessage();
    this.socketService.getMessage();
    this.userSubs = this.userService.getUpdateUserListner().subscribe(data => {
      this.user = data.result;
      //console.log(this.user);
    }, error => {
      console.log(error);
    });

    this.messageSubs = this.socketService.getUpdateMessageListner().subscribe(data => {
      this.message = data.message;
    });

  }

  closeNavbar() {
    document.getElementById('navicon').click();
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.userSubs.unsubscribe();
    this.messageSubs && this.messageSubs.unsubscribe();
  }
}
