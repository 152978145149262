import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../service/auth.service';

@Injectable()
export class LevelGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        let isAuth = false;
        let level = this.authService.getUserLevel();
        if (level == '8') {
            isAuth = true;
        }

        if (!isAuth) {
            this.router.navigate(['/auth']);
        }
        return isAuth;
    }
}