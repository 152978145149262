import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './service/auth.service';
import { SocketService } from './service/socket.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from 'src/app/service/user.service';
import swal from "sweetalert2";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,AfterViewInit, OnDestroy {
  title = 'TrophyClient';
  isLogin = false;
  level: string;
  userId = '';
  isMobile: Boolean;
  timeStamp: string;
  private authListenerSubs: Subscription;
  private logoutSubs: Subscription;
  showModal: boolean = false;
  checkpopup: boolean = false;
  submitted: Boolean;
  passwordForm: FormGroup;
  private pSubs: Subscription;

  constructor(private authService: AuthService, private router: Router, private socketService: SocketService ,private formBuilder: FormBuilder, private userService: UserService, private spinner: NgxSpinnerService) { }

  ngOnInit() {    
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (localStorage.getItem('exA') == '1') {
        this.showpopup();
      }
    });
      // Perform the HTTP GET request
    
   
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    this.authService.autoAuthUser();
    this.isLogin = this.authService.getIsAuth();
    if (this.isLogin) {
      this.socketService.connect();
      this.firebaseTriggered();
    }
    this.authListenerSubs = this.authService
      .getAuthStatusListner()
      .subscribe((IsAuthenticated) => {
        this.isLogin = IsAuthenticated;
        if (this.isLogin) {
          this.socketService.connect();
          this.firebaseTriggered();
        }
        else {
          this.logoutSubs && this.logoutSubs.unsubscribe();
          this.socketService.disconnect();
          this.timeStamp = '';
        }
      });


      this.passwordForm = this.formBuilder.group({
        oldPassword: ['', [Validators.required]],
        newPassword: ['', Validators.required],
        rePassword: ['', Validators.required]
      }, {
        validator: this.MustMatch('newPassword', 'rePassword')
      });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (localStorage.getItem('exA') == '1') {
      this.showpopup();
    }
  }

  firebaseTriggered() {
    this.userId = this.authService.getUserId();
    if (this.userId) {
      this.logoutSubs = this.socketService.getFirebaseT(this.userId).valueChanges().subscribe(data => {
        this.timeStamp = this.authService.getTimeStamp();
        if (data?.timeStamp != this.timeStamp && data?.timeStamp > this.timeStamp) {
          this.authService.dlogout();
        }
      });
    }
  }

  showpopup() {
    this.onReset()
    this.showModal = true;
    this.checkpopup = true;
    // console.log(this.showModal);
  }

  hidepopup() {
    this.showModal = false;
    this.checkpopup = false;
  }




  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  get f() { return this.passwordForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    if (this.passwordForm.invalid) {
      this.spinner.hide();
      return;
    }

    //console.log(this.passwordForm.value);
    this.pSubs = this.userService.changeUserPassword(this.f.oldPassword.value, this.f.newPassword.value, this.f.rePassword.value).subscribe((data:any) => {
      //console.log(data);
      this.hidepopup();
      this.spinner.hide();
      this.msgPopup(data['message'], data['status']);
      this.onReset()
      if(data.status == 'success'){
       this.logout()
      }else{
        this.showpopup();
      }
    }, error => {
      //console.log(error);
      this.showpopup();
      this.spinner.hide();
      this.msgPopup(error.error['message'], 'error');
      this.submitted = false;
    });
  }

  logout(){
    this.hidepopup()
    this.onReset()
    this.authService.logout();
  }

  onReset() {
    this.submitted = false;
    this.passwordForm.reset();
  }

  msgPopup(title, icon) {
    swal.fire({
      icon: icon,
      title: title,
      showConfirmButton: false,
      timer: 1500
    })
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
    this.logoutSubs && this.logoutSubs.unsubscribe();
  }
}