<section class="header-section w-100">
    <header role="banner" class="navbar navbar-fixed-top navbar-inverse navbar-background-color p-0">
        <nav class="navbar navbar-expand-md bg-dark-wrapper navbar-dark w-100 p-0">
            <!-- Brand -->
            <a class="navbar-brand" routerLink="/" style="    display: grid;">
                <img src="../../../assets/images/heroic_logo.png" style="width: 45px;">
                <!-- <span style="font-size: 11px;
                margin-left: 10px;">GURU</span> -->
            </a>
            <div class="user_profile_detail coinmobile" >
                <span class="header-user-nm" style="    margin-left: 50px;">{{ userName }} - GURU7777</span><br>
                <span style="color: #fff;  margin-left: 45px;">
                    <span class="header-user-coins" style="
                    align-items: center;">
                    <!-- <img src="./assets/images/dollar.png" width="15" style="margin-right: 5px;"> -->
                     BAL: <span style="color:#07ff4a;"><b> {{ (user?.coins || 0) | number: '1.2-2' }}</b></span></span> / 
                </span>
                <span style="margin-left:0px; " class="header-user-coins">EXPO: <span style="color: #fffa00;"><b>{{ (user?.liability || 0) |
                    number:
                    '1.2-2' }}</b> </span></span>
            </div>
            <!-- <div class="user_profile_detail coinmobile" >
                <span class="header-user-nm">{{ userName }}</span><br>
                <span style="color: #fff; display: flex;">
                    <span class="header-user-coins" style="
                    align-items: center;">
                    <img src="./assets/images/dollar.png" width="15" style="margin-right: 5px;">
                        Coins: <span style="color:#07ff4a;"><b> {{ (user?.coins || 0) | number: '1.2-2' }}</b></span></span>
                </span>
                <span style="margin-left:0px; " class="header-user-coins">Used : <span style="color: #ff0000;"><b>{{ (user?.liability || 0) |
                    number:
                    '1.2-2' }}</b> </span></span>
            </div> -->
            <!-- Toggler/collapsibe Button -->
            <button id="navicon" style="float: right;" class="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#collapsibleNavbar">
                <!-- <span class="navbar-toggler-icon"></span> -->
                <i class="fa fa-home" aria-hidden="true"></i>
            </button>

            <!-- Navbar links -->
            <div class="collapse navbar-collapse" id="collapsibleNavbar">
                <ul class="navbar-nav ml-auto">
                    <li (click)="closeNavbar()" class="nav-item">
                        <a class="nav-link" routerLink="/dashboard">
                            <span class="icon-wrapper">
                                <i class="fa fa-home" aria-hidden="true"></i>
                            </span>HOME</a>
                    </li>
                    <li (click)="closeNavbar()" class="nav-item">
                        <a class="nav-link" routerLink="/inplay/upComing">
                            <span class="icon-wrapper">
                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                SCHEDULE
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a style="cursor:pointer;" class="nav-link" (click)="logout()">
                            <span class="icon-wrapper">
                                <i class="fa fa-circle-o-notch" aria-hidden="true"></i>
                                LOG OUT
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
    <div class="col-md-12 " style="padding: 0px;     margin-top: 20px;
    margin-bottom: 20px;">
        <ul class="marquee">
            <marquee>
                <li id="marqmessage">{{ message }} </li>
            </marquee>
        </ul>
    </div>
</section>