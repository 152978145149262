import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { io } from 'socket.io-client';
import { Observable, Observer } from 'rxjs';
import { AuthService } from './auth.service';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';

const SOCKET_URL = environment.socketUrl;
const SOCKET_URL_2 = environment.socketUrl2;

@Injectable({ providedIn: "root" })
export class SocketService {
    private socket: any;
    private socket2: any;
    public odds: any;
    public fancy: any;
    // public bookM: any;
    public khado: any;
    public score: any;
    public match: any;
    // public user: any;
    // public userL: any;
    public message: any;
    public casino: any;
    public casinoResult: any;
    public token: String;
    private oddsUpdated: Observer<{ odds: any, eventId: String }>;
    private fancyUpdated: Observer<{ fancy: any, eventId: String }>;
    //private bookMUpdated: Observer<{ bookM: any, eventId: String }>;
    private khadoUpdated: Observer<{ khado: any, eventId: String }>;
    private scoreUpdated: Observer<{ score: any }>;
    private matchUpdated: Observer<{ match: any }>;
    //private userUpdated: Observer<{ user: any }>;
    //private userLUpdated: Observer<{ userL: any }>;
    private messageUpdated: Observer<{ message: any }>;
    private casinoUpdated: Observer<{ casino: any }>;
    private casinoResultUpdated: Observer<{ casinoResult: any }>;
    //private oddsRef: AngularFireList<any>;
    //private fancyRef: AngularFireList<any>
    private triggered: AngularFireObject<any>;
    constructor(private authService: AuthService, private db: AngularFireDatabase) {
    }

    public connect = () => {
        this.token = this.authService.getToken();
        this.socket = io(SOCKET_URL, {
            transports: ['websocket'],
            forceNew: true,
            query: {
                "token": `${this.token}`
            }
        });
    }

    public oConnect = () => {
        this.socket2 = io(SOCKET_URL_2, {
            transports: ['websocket'],
            forceNew: true
        });

        // this.socket3 = io("http://139.59.82.99:3000", {
        //     transports: ['websocket'],
        //     forceNew: true,
        // });
    }

    public setOdds = (eventId) => {
        this.socket2.emit('Market', eventId);
    }

    public getOdds = (eventId) => {
        this.socket2.on('Market/' + eventId, (odds) => {
            //console.log(odds);
            this.odds = odds;
            this.oddsUpdated.next({
                odds: this.odds,
                eventId
            });
        });
    }

    getUpdateOddsListner(): Observable<any> {
        return new Observable(observer => {
            this.oddsUpdated = observer;
        });
    }

    public setFancy = (eventId) => {
        this.socket2.emit('ASession', eventId);
    }

    public getFancy = (eventId) => {
        this.socket2.on('ASession/' + eventId, (fancy) => {
            //console.log(odds);
            this.fancy = fancy;
            this.fancyUpdated.next({
                fancy: this.fancy,
                eventId
            });
        });
    }

    getUpdateFancyListner(): Observable<any> {
        return new Observable(observer => {
            this.fancyUpdated = observer;
        });
    }

    // public setBookM = (eventId) => {
    //     this.socket.emit('BookM/Auto', eventId);
    // }

    // public getBookM = (eventId) => {
    //     this.socket.on('BookM/Auto/' + eventId, (bookM) => {
    //         //console.log(odds);
    //         this.bookM = bookM;
    //         this.bookMUpdated.next({
    //             bookM: this.bookM,
    //             eventId
    //         });
    //     });
    // }

    // getUpdateBookMListner(): Observable<any> {
    //     return new Observable(observer => {
    //         this.bookMUpdated = observer;
    //     });
    // }

    public setKhado = (eventId) => {
        this.socket.emit('Khado', eventId);
    }

    public getKhado = (eventId) => {
        this.socket.on('Khado/' + eventId, (khado) => {
            //console.log(data);
            this.khado = khado;
            this.khadoUpdated.next({
                khado: this.khado,
                eventId
            });
        });
    }

    public getUpdateKhadoListner(): Observable<any> {
        return new Observable(observer => {
            this.khadoUpdated = observer;
        });
    }

    public setScore = (eventId) => {
        this.socket2.emit('Score', eventId);
    }

    public getScore = (eventId) => {
        this.socket2.on('Score/' + eventId, (score) => {
            // console.log('score', score);
            this.score = score;
            this.scoreUpdated.next({
                score: this.score
            });
        });
    }

    getUpdateScoreListner(): Observable<any> {
        return new Observable(observer => {
            this.scoreUpdated = observer;
        });
    }

    public setMatch = (eventId) => {
        this.socket.emit('Match', eventId);
    }

    public getMatch = (eventId) => {
        this.socket.on('Match/' + eventId, (data) => {
            //console.log(book);
            this.match = data;
            this.matchUpdated.next({
                match: this.match
            });
        });
    }

    public getUpdateMatchListner(): Observable<any> {
        return new Observable(observer => {
            this.matchUpdated = observer;
        });
    }

    // public setUser = () => {
    //     this.socket2.emit('User', '');
    // }

    // public getUser = () => {
    //     this.socket2.on('User', (data) => {
    //         //console.log(book);
    //         this.user = data;
    //         this.userUpdated.next({
    //             user: this.user
    //         });
    //     });
    // }

    // public getUserL = () => {
    //     this.socket2.on('UserL', (data) => {
    //         this.userL = data;
    //         this.userLUpdated.next({
    //             userL: this.userL
    //         });
    //     });
    // }

    // public getUpdateUserListner(): Observable<any> {
    //     return new Observable(observer => {
    //         this.userUpdated = observer;
    //     });
    // }

    // public getUpdateLUserListner(): Observable<any> {
    //     return new Observable(observer => {
    //         this.userLUpdated = observer;
    //     });
    // }

    public getFirebaseT = (userId) => {
        this.triggered = this.db.object('users/' + userId);
        return this.triggered;
    }

    public setMessage = () => {
        this.socket.emit('Message', 'client');
    }

    public getMessage = () => {
        this.socket.on('Message', (data) => {
            this.message = data.description;
            this.messageUpdated.next({
                message: this.message
            });
        });
    }

    public getUpdateMessageListner(): Observable<any> {
        return new Observable(observer => {
            this.messageUpdated = observer;
        });
    }

    public setCasino = (eventId) => {
        this.socket2.emit('Casino', eventId);
        this.socket2.emit('CasinoResult', eventId);
    }

    public getCasino = (eventId) => {
        this.socket2.on('Casino/' + eventId, (casino) => {
            this.casino = casino;
            this.casinoUpdated.next({
                casino: this.casino
            });
        });

        this.socket2.on('CasinoResult/' + eventId, (casino) => {
            this.casinoResult = casino;
            this.casinoResultUpdated.next({
                casinoResult: this.casinoResult
            });
        });
    }

    getUpdateCasinoListner(): Observable<any> {
        return new Observable(observer => {
            this.casinoUpdated = observer;
        });
    }

    getUpdateCasinoResultListner(): Observable<any> {
        return new Observable(observer => {
            this.casinoResultUpdated = observer;
        });
    }

    public destoryCricketSocket = (eventId) => {
        this.socket.off('Match/' + eventId);
        this.socket.off('Khado/' + eventId);
        this.socket.emit('destroy_cricket_room', eventId);
    }

    public disconnect = () => {
        this.socket && this.socket.disconnect();
    }

    public disconnect2 = () => {
        this.socket2 && this.socket2.disconnect();
        //this.socket3 && this.socket3.disconnect();
    }
}